// @ts-nocheck
import React, {useEffect, useState} from "react";
import HomeSelectionCard from "../HomeSelectionCard";
import {Row, Col, Grid, Modal} from 'antd';
import {calculationConfig} from "../../config/calculationConfig";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import _ from "lodash";
import './index.css';
import {useMutation} from "@apollo/client";
import {CREATE_CALCULATION} from "../../graphql/mutations/createCalculation";
import {useDispatch, useSelector} from "react-redux";
import {DataActionType} from "../../redux/reducers";
import {Calculation} from "../../types";
import {isLoggedIn} from "../../redux/selectors/user";
import {toast} from "react-toastify";
import ToastContent from "../ToastContent";
import {Button} from "@dreebit/pv-components";
import KeycloakService from "../../service/keycloak";
import useSeachQuery from "../../hooks/useSeachQuery";
import SavedCalculationsList from "../SavedCalculationsList";
import {getExternalConnection} from "../../config/externalConnections";

interface Props {
    onMobileContent: (content: string) => void
}

export default function Home({onMobileContent}: Props) {

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const loggedIn = useSelector(isLoggedIn);
    let urlSearchQuery = useSeachQuery();
    const [calculationsVisible, setCalculationsVisible] = useState(false);
    const [primaryCardActive, setPrimaryCardActive] = useState(urlSearchQuery.get("calculationMode") || '');
    const [secondaryCardActive, setSecondaryCardActive] = useState(urlSearchQuery.get("calculationType") || '');
    const keycloak = KeycloakService.getInstance();

    const [createCalculation, { data, loading, error }] = useMutation(CREATE_CALCULATION);

    const toggleCalculationVisibility = () => {
        setCalculationsVisible(!calculationsVisible);
    };

    const setActive =(calculationId:string) => {
        const params = urlSearchQuery;
        if (calculationId === primaryCardActive) {
            setPrimaryCardActive('')
            setSecondaryCardActive('')
            params.delete("calculationMode")
            params.delete("calculationType")
        }
        else {
            if (params.get("calculationMode")) {
                params.delete("calculationMode")
                params.delete("calculationType")
            }
            params.append("calculationMode", calculationId)
            setPrimaryCardActive(calculationId)
        }
        history.push({search: params.toString()})
    }

    const setSecondaryActive = (subCalculationId:string) => {const calculationMode = calculationConfig.filter(prim => prim.id === primaryCardActive)[0]
        const calculationType = calculationMode.subCalculations?.filter(sec => sec.id === subCalculationId)[0]

        if (!calculationType.disabled) {
            createCalculation({
                variables: {
                    calculationMode: _.get(calculationMode, 'id'),
                    calculationType: _.get(calculationType, 'id')
                }
            }).then((res) => {
                const calculationId = _.get(res, 'data.createCalculation.id', '')
                const route = '/' + _.get(calculationMode, 'id') + '/' + _.get(calculationType, 'id') + '/' + calculationId

                dispatch({
                    type: DataActionType.CLEAR_SUMMARY_TABS
                });

                history.push(route);

            }).catch((err) => {
                const errorObject=JSON.parse(JSON.stringify(err));
                const errorMessage = _.get(errorObject, 'networkError.result.errors[0].message', errorObject.message)

                toast.error(<ToastContent
                    status={"error"}
                    headline={t('Error creating calculation')}
                    text={`${errorMessage}`}
                />, {
                    autoClose: 7000,
                    pauseOnHover: true
                })
            })
        }
    }

    const selectCard = (calculation: Calculation) => {
        if (!calculation.disabled) {
            setActive(calculation.id)
        }
    }

    useEffect(() => {
        const activeCalculationMode = calculationConfig.find(calc => calc.id === primaryCardActive)
        if (activeCalculationMode && activeCalculationMode.loginNeeded && !loggedIn) {
            return keycloak.login({
                locale: i18n?.language,
            })
        }
    }, [primaryCardActive, loggedIn])

    return (
    <>
        <div className={'pt-40'}>
            <div>
                <h1 className={'font-bold'}>{t('Vacuum Calculator')}</h1>
                <h2>{t('Find the optimal pump for your application')}</h2>
            </div>
            <div className={'mt-24 mb-16'}>
                <h4 className={'font-bold'}>{t('vacuumCalculationSelection')}</h4>
            </div>
            <Row gutter={16} className={"home-card-wrapper"}>
                {calculationConfig.map((key:any)=> {
                    return <Col
                        key={key.id}
                        span={screens.md ? '8' : '24'}
                        className={`gutter-col ${screens.md ? '' : 'mt-16'}`}
                    >
                        <HomeSelectionCard
                            id={key.id}
                            onClick={() => selectCard(key)}
                            type={!!key.disabled ? 'disabled' : key.id === primaryCardActive ? 'active' : 'default'}
                            title={t(key.title)}
                            subtitle={t(key.subtitle)}
                        />
                    </Col>
                })}
            </Row>
            <div className={`mt-48 mb-8 ${screens.md ? 'flex-row flex-space-between' : 'flex-col'}`}>
                <h4 className={'font-bold'}>{t('vacuumCalculationPurpose')}</h4>
            </div>
            <Row gutter={primaryCardActive === '' ? 0 : 16} className={'home-card-wrapper mb-40'}>
                {primaryCardActive === '' ?
                    <Col
                        className={'gutter-col p-16 info-gr-bg full-width flex'}
                        span={'24'}
                    >
                        <ExclamationCircleOutlined className={'icon-hint'}/>
                        <p className={'text-hint mv-0'}>
                            {t('Please select your type of calculation first.')}
                        </p>
                    </Col>
                    :
                    calculationConfig.filter(x => x.id === primaryCardActive).map((key: any) => {
                        return key.subCalculations.map((step: any) => {
                            const cardWidth = 24 / _.size(key.subCalculations)

                            return (
                                <Col
                                    key={key.id}
                                    span={screens.md ? cardWidth : '24'}
                                    className={`gutter-col ${screens.md ? '' : 'mt-16'}`}
                                >
                                    <HomeSelectionCard
                                        id={step.id}
                                        onClick={() => setSecondaryActive(step.id)}
                                        type={!!step.disabled ? 'disabled' : step.id === secondaryCardActive ? 'active' : 'default'}
                                        title={t(step.title)}
                                        subtitle={t(step.subtitle)}
                                    />
                                </Col>
                            )
                        })
                    })
                }
            </Row>
            <Row gutter={16} align={"middle"}>
                <Col
                    className={'flex-row flex-space-between flex-align-items-center'}
                    flex={"auto"}
                >
                    <Button
                        type={"ghost"}
                        className={`${!screens.md && loggedIn ? 'mb-32' : ''}`}
                        icon={"arrow_back"}
                        iconPosition={"left"}
                        onClick={() => window.open(getExternalConnection("ecommerceWebportal") + 'global/' + (i18n?.language || "en"), '_self')}
                    >
                        {t('Back to the web portal')}
                    </Button>
                </Col>
                <Col flex={`${screens.md ? 'none' : '2.5'}`}>
                    {
                        loggedIn &&
                        <Button
                            type={"tertiary"}
                            className={`${screens.md ? 'ml-8' : ''}`}
                            onClick={screens.md ? toggleCalculationVisibility : () => onMobileContent("savedCalculations")}
                            style={{width: screens.md ? "auto" : "100%"}}
                            icon={"open_in_new"}
                        >
                            {t('Open calculations')}
                        </Button>
                    }
                </Col>
                {/*<Col flex={`${screens.md ? 'none' : '2.5'}`}>*/}
                {/*    <Button*/}
                {/*        type={"primary"}*/}
                {/*        data-testid={`start-calculation-button`}*/}
                {/*        className={`${screens.md ? 'ml-8' : ''}`}*/}
                {/*        style={{width: screens.md ? "auto" : "100%"}}*/}
                {/*        disabled={secondaryCardActive === ''}*/}
                {/*        onClick={secondaryCardActive !== '' ? () => nextStep() : () => {}}*/}
                {/*        icon={"arrow_forward"}*/}
                {/*    >*/}
                {/*        {t('Next')}*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
        </div>
        <Modal
            title={t('Open saved calculations')}
            visible={calculationsVisible}
            onCancel={toggleCalculationVisibility}
            footer={null}
            width={880}
        >
            <SavedCalculationsList
                showCalculationType={true}
            />
        </Modal>
    </>
    )
}
