// @ts-nocheck
import Keycloak from "keycloak-js/dist/keycloak.js";
import _ from "lodash";

let _keycloak = process.env.NODE_ENV === 'development'
    // @ts-ignore
    ? new Keycloak({
        "url": "https://dev.sso.pfeiffer-vacuum.com/auth/",
        "realm": "dev",
        "clientId": "vsm-public"
    })
    : _.get(window, '__BE__.keycloak')
        // @ts-ignore
        ? new Keycloak({
            ..._.get(window, '__BE__.keycloak')
        })
        : null;

export default class KeycloakService {
    static getInstance = (keycloakObject?: any) => {
        if(keycloakObject) { // @ts-ignore
            _keycloak = new Keycloak({...keycloakObject});
        }
        return _keycloak
    }

    static init = () => {
        return _keycloak.init({
            onLoad: 'check-sso',
        })
    }

    static getUser = () => {
        if (_keycloak.idToken) {
            return _keycloak.idTokenParsed.name
        } else {
            _keycloak.loadUserProfile(function() {
                return _keycloak.profile.firstName + ' ' + _keycloak.profile.lastName;
            }, function() {
                return console.log('Failed to retrieve user details.');
            });
        }
    }

    static logout = () => {
        if (!_keycloak) return Promise.resolve();

        try {
            if (_keycloak.clearToken) _keycloak.clearToken();

            return _keycloak.logout({
                redirectUri: window.location.href,
            });

        } catch (e) {
            return Promise.resolve()
        }
    }

    static performVSMLogin = (): Promise<any> => {
        return fetch("/sso/token", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + KeycloakService.getInstance().token
            }
        }).then((response) => {
            return response.json()
        })
    }
};
